<template>
  <q-page class="q-pa-sm">
    <div class="row">

      <div class="col-8">
        <q-item>
          <q-input v-model="entity.header" :rules="[val => $validators.required(val)]" class="full-width" clearable
                   dense
                   label="Название" outlined/>
        </q-item>
      </div>

      <div class="col-4">
        <q-item>
          <q-input v-model="entity.price"
                   :rules="[val => $validators.positiveNumber(val) || 'Только положительное число']" class="full-width"
                   dense
                   label="Стоимость в СпасибоРублях" outlined type="number">
            <template v-slot:prepend>
              <q-icon name="currency_ruble"/>
            </template>
          </q-input>
        </q-item>
      </div>

      <div class="col-12">
        <q-item>
          <q-input v-model="entity.description" autogrow class="full-width" clearable dense label="Описание" outlined/>
        </q-item>
      </div>

      <div class="col-12">
        <q-item>
          <q-input v-model="entity.mechanics" autogrow class="full-width" clearable dense
                   label="Условия применения акции"
                   outlined/>
        </q-item>
      </div>

      <div class="col-6">
        <q-item>
          <q-input v-model="entity.redirect_url" :rules="[val => $validators.isValidUrl(val)]" class="full-width"
                   clearable dense label="Сайт" outlined/>
        </q-item>
      </div>

      <div class="col-6">
        <q-item>
          <q-input v-model="entity.button_text" :rules="[val => $validators.required(val)]" class="full-width" clearable
                   dense label="Текст кнопки" outlined/>
        </q-item>
      </div>

      <div class="col-12">
        <q-card bordered class="q-mx-md q-pa-none q-mb-md" flat>
          <q-card-section horizontal>
            <q-card-section>
              <span v-if="!entity.start_time" class="text-accent">
                Срок действия акции не указан
              </span>
              <span v-else>
                Срок действия акции: {{ $filters.toHumanDate(entity.start_time) }}
                -
                {{ $filters.toHumanDate(entity.end_time) }}
              </span>
              <q-btn class="q-ml-lg" icon="edit" round size="sm">
                <q-popup-proxy cover transition-hide="scale" transition-show="scale" @before-show="updateDateRange">
                  <q-date v-model="dtRange" range>
                    <div class="row items-center justify-end q-gutter-sm">
                      <q-btn v-close-popup color="primary" flat label="Отменить"/>
                      <q-btn v-close-popup color="primary" flat label="OK" @click="saveDateRange"/>
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-btn>
            </q-card-section>

            <q-space/>
            <q-card-section>
              <span v-if="!entity.expiration_time" class="text-grey">
                Срок годности купонов не указаны
              </span>
              <span v-else>
                Срок годности купонов:
                {{ $filters.toHumanDate(entity.expiration_time) }}
              </span>
              <q-btn class="q-ml-lg" icon="edit" round size="sm">
                <q-popup-proxy cover transition-hide="scale" transition-show="scale" @before-show="updateDateRange">
                  <q-date v-model="entity.expiration_time" mask="YYYY-MM-DD">
                    <div class="row items-center justify-end q-gutter-sm">
                      <q-btn v-close-popup color="primary" flat label="Очистить"
                             @click="entity.expiration_time = null"/>
                      <q-btn v-close-popup color="primary" flat label="OK"/>
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-btn>
            </q-card-section>
          </q-card-section>
        </q-card>
      </div>

      <div v-if="entityId" class="col-4">
        <q-item>
          <image-uploader v-model="entity.small_image_url"
                          :uploadMethod="file => new MarketApi().offerControllerUploadImage(file)" class="full-width"
                          label="Баннер для акции малый (PNG)"/>
        </q-item>
      </div>

      <div v-if="entityId" class="col-8">
        <q-item>
          <image-uploader v-model="entity.image_url"
                          :uploadMethod="file => new MarketApi().offerControllerUploadImage(file)" class="full-width"
                          label="Баннер для акции большой (PNG)"/>
        </q-item>
      </div>

      <div class="col-6">
        <q-item>
          <q-select v-model="entity.category" :option-label="value => categoriesLabelMap[value]" :options="categories"
                    class="full-width" dense label="Категории" multiple outlined use-chips/>
        </q-item>
      </div>

      <div class="col-6">
        <q-item>
          <q-select v-model="entity.visibility" :option-label="value => visibilityLabelMap[value]"
                    :options="['partner_qr', 'person_qr']" class="full-width" dense label="Параметры отображения купона"
                    multiple
                    outlined use-chips/>
        </q-item>
      </div>

      <div class="col-6">
        <q-item>
          <q-select v-model="entity.min_credit_from_organization_no"
                    :option-label="value => organizationsLabelMap[value]"
                    :options="organizationsFiltered" class="full-width" clearable dense input-debounce="0"
                    label="Партнёр, от которого необходимо получить начисления, чтобы приобрести предложение" outlined
                    use-input
                    @filter="filterOrganizations"/>
        </q-item>
      </div>

      <div v-if="entity.min_credit_from_organization_no" class="col-6">
        <q-item>
          <q-input v-model="entity.min_credit_value"
                   :rules="[val => !val || $validators.positiveNumber(val) || 'Только положительное число']"
                   class="full-width"
                   dense label="Минимальная сумма всех начислений от партнёра" outlined type="number">
            <template v-slot:prepend>
              <q-icon name="currency_ruble"/>
            </template>
          </q-input>
        </q-item>
      </div>

    </div>

    <div class="q-pa-md">
      <q-btn :loading="loading" class="q-mr-md" color="primary" icon="save" label="Сохранить" rounded
             @click="saveEntity"/>

      <q-btn class="q-mr-md" icon="cancel" label="Назад" rounded @click="$router.back()"/>
    </div>
  </q-page>
</template>

<script>
import { AdminApi } from '@/api/apis/admin-api.ts';
import { MarketApi } from '@/api/apis/market-api.ts';
import ImageUploader from '@/components/forms/imageUploader';
import { Notify } from 'quasar';
import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  components: {
    ImageUploader,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const entityId = computed(() => route.params.offer_id);
    const oldData = {
      start_time: '2001-01-01T00:00:00Z',
      price: '1.0000',
    }
    const entity = ref({
      button_text: 'Купить',
      description: 'Купите и получите скидку на следующую покупку',
      header: 'Акция',
      image_url: 'http://example.com/image',
      mechanics: 'Условия акции',
      offer_type: 'coupon',
      price: '1.0000',
      priority: 15,
      redirect_url: 'http://example.com/offer.html',
      small_image_url: 'http://example.com/small-image',
      end_time: '2001-01-02T00:00:00Z',
      expiration_time: null,
      min_credit_from_organization_no: 0,
      min_credit_value: '1.0000',
      sale_limit: null,
      start_time: '2001-01-01T00:00:00Z',
      autogenerated: true,
      visibility: [
        'partner_qr',
      ],
      // for_adults: true,
      category: [
        'entertainment',
      ],
    });

    const idChanged = () => {
      if (entityId.value) {
        new MarketApi().offerControllerGetOffer(entityId.value).then(({ data }) => {
          entity.value = {
            ...data,
            ...data.offer_rule,
            ...data.meta?.coupon,
            category: data.categories.map(({ name }) => name),
          };
          oldData.start_time = data.offer_rule.start_time
          oldData.price = data.price
        }).catch(() => entityId.value = 0);
      }
    };
    watch(() => entityId.value, idChanged);
    onMounted(idChanged);

    const dtRange = ref({
      from: '2020/07/08',
      to: '2020/07/17',
    });

    const loading = ref(false);
    const categories = ref([]);
    const categoriesLabelMap = ref({});

    new MarketApi().marketControllerGetCategories().then(({ data }) => {
      categories.value = data.map((item) => item.name);

      data.forEach((item) => {
        categoriesLabelMap.value[item.name] = item.description;
      });
    });

    const organizationsFiltered = ref([]);
    const organizations = ref([]);
    const organizationsLabelMap = ref({});

    new AdminApi().organizationControllerFindOrganizations().then(({ data }) => {
      organizations.value = data.map((item) => item.account_no);

      data.forEach((item) => {
        organizationsLabelMap.value[item.account_no] = item.name;
      });
    });

    return {
      categories,
      categoriesLabelMap,
      organizations,
      organizationsFiltered,
      organizationsLabelMap,
      filterOrganizations(val, update) {
        if (val === '') {
          update(() => {
            organizationsFiltered.value = organizations.value;
          });
          return;
        }

        update(() => {
          const needle = val.toLowerCase();
          organizationsFiltered.value = [];
          for (const id in organizationsLabelMap.value) {
            if (organizationsLabelMap.value[id].toLowerCase().has(needle)) {
              organizationsFiltered.value.push(id);
            }
          }
        });
      },
      visibilityLabelMap: {
        partner_qr: 'Считать QR код сотрудника',
        person_qr: 'Генерация QR кода в приложении',
      },
      MarketApi,
      entity,
      entityId,
      dtRange,
      loading,

      updateDateRange() {
        dtRange.value = {
          from: entity.value.start_time,
          to: entity.value.end_time,
        };
      },

      saveDateRange() {
        entity.value.start_time = new Date(dtRange.value.from).toISOString();
        entity.value.end_time = new Date(dtRange.value.to).toISOString();
      },

      async saveEntity() {
        try {
          loading.value = true;
          delete entity.value.offer_id;

          if (!entity.value.min_credit_from_organization_no) {
            entity.value.min_credit_from_organization_no = undefined;
            entity.value.min_credit_value = undefined;
          }

          if (entityId.value) {
            if (entity.value.offer_status !== 'draft' && (entity.value.start_time !== oldData.start_time || entity.value.price !== oldData.price)){
              return Notify.create({
                type: 'negative',
                message: 'Нельзя изменять стоимость или дату начала акции, если она принята',
              });
            }
            await new MarketApi().offerControllerUpdateOffer(
              {
                button_text: entity.value.button_text,
                description: entity.value.description,
                header: entity.value.header,
                image_url: entity.value.image_url,
                mechanics: entity.value.mechanics,
                offer_type: entity.value.offer_type,
                price: entity.value.offer_status === 'draft' ? entity.value.price : undefined,
                priority: entity.value.priority,
                redirect_url: entity.value.redirect_url,
                small_image_url: entity.value.small_image_url,
                end_time: entity.value.end_time,
                expiration_time: entity.value.expiration_time,
                min_credit_from_organization_no: entity.value.min_credit_from_organization_no,
                min_credit_value: entity.value.min_credit_value,
                sale_limit: entity.value.sale_limit,
                start_time: entity.value.offer_status === 'draft' ? entity.value.start_time : undefined,
                autogenerated: entity.value.autogenerated,
                visibility: entity.value.visibility,
                for_adults: entity.value.for_adults,
                category: entity.value.category,
                offer_status: entity.value.offer_status,
              },
              entityId.value,
            );
          } else {
            entity.value.vendor_no = route.params.vendor_no;
            const { data } = await new MarketApi().offerControllerCreateOffer(entity.value);
            if (data?.offer_id) {
              router.replace(`/offers/addedit/${route.params.vendor_no}/${data?.offer_id}`);
            }
          }

          Notify.create({
            type: 'positive',
            message: 'Сохранено',
          });
        } catch (e) {
          console.error(e);
        }finally {
          loading.value = false;
        }
      },
    };
  },
});
</script>

<style></style>
