/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '@/api';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BadRequestException } from '../models';
import { CategoryDto } from '../models';
import { CreateCampaignPayloadDto } from '../models';
import { CreateCampaignResponseDto } from '../models';
import { CreateOfferPayloadDto } from '../models';
import { CreatePromocodeGeneratorTaskPayloadDto } from '../models';
import { CreatePromocodePayloadDto } from '../models';
import { DeleteCampaignPayloadDto } from '../models';
import { ForbiddenException } from '../models';
import { GetCampaignResponseDto } from '../models';
import { GetOffersResponseDto } from '../models';
import { GetPromocodesDto } from '../models';
import { GetSequenceResponseDto } from '../models';
import { ModelObject } from '../models';
import { NotFoundException } from '../models';
import { OfferDataDto } from '../models';
import { OfferDataWithPatchDto } from '../models';
import { OfferDataWithPersonalDataDto } from '../models';
import { OfferPatchDto } from '../models';
import { OfferPatchResolutionDto } from '../models';
import { OfferStatusPayloadDto } from '../models';
import { OpportunitiesListDto } from '../models';
import { OpportunityDataDto } from '../models';
import { OpportunityDto } from '../models';
import { OpportunityPositionDto } from '../models';
import { OpportunityPositionListItemDto } from '../models';
import { PostPromocodeQueryFieldsDto } from '../models';
import { PromocodeGeneratorTaskDto } from '../models';
import { PurchasePayloadDto } from '../models';
import { PurchaseResponseDto } from '../models';
import { UnauthorizedException } from '../models';
import { UpdateCampaignDownloadUrlDto } from '../models';
import { UpdateCampaignPayloadDto } from '../models';
import { UpdateCampaignStatusPayloadDto } from '../models';
import { UpdateOfferPayloadDto } from '../models';
import { UploadImageResponseDto } from '../models';
import { UsePromocodeResponseDto } from '../models';

/**
 * MarketApi - axios parameter creator
 * @export
 */
export const MarketApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Создание акции
     * @param {CreateCampaignPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerCreateCampaign: async (body: CreateCampaignPayloadDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling campaignControllerCreateCampaign.');
      }
      const localVarPath = `/api/2.0/market/campaign`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создание задачи на генерацию QR-кодов
     * @param {CreatePromocodeGeneratorTaskPayloadDto} body
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerCreatePromocodeGeneratorTask: async (body: CreatePromocodeGeneratorTaskPayloadDto, campaignId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling campaignControllerCreatePromocodeGeneratorTask.');
      }
      // verify required parameter 'campaignId' is not null or undefined
      if (campaignId === null || campaignId === undefined) {
        throw new RequiredError('campaignId', 'Required parameter campaignId was null or undefined when calling campaignControllerCreatePromocodeGeneratorTask.');
      }
      const localVarPath = `/api/2.0/market/campaign/{campaign_id}/promocode-generator-task`
        .replace(`{${'campaign_id'}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление акции
     * @param {DeleteCampaignPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerDeleteCampaign: async (body: DeleteCampaignPayloadDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling campaignControllerDeleteCampaign.');
      }
      const localVarPath = `/api/2.0/market/campaign`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список акций
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [status] Статус акции
     * @param {number} [id] ID акции
     * @param {number} [vendor] ID продавца
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerGetCampaignList: async (skip?: number, take?: number, start?: string, end?: string, order?: string, status?: string, id?: number, vendor?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/campaign`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (vendor !== undefined) {
        localVarQueryParameter['vendor'] = vendor;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список промокодов в кампании
     * @param {number} campaignId ID кампании
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerGetPromoCodes: async (campaignId: number, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      if (campaignId === null || campaignId === undefined) {
        throw new RequiredError('campaignId', 'Required parameter campaignId was null or undefined when calling campaignControllerGetPromoCodes.');
      }
      const localVarPath = `/api/2.0/market/campaign/{campaign_id}/promocode`
        .replace(`{${'campaign_id'}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Запрос состояния задач генерации QR-кодов
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerGetPromocodeGeneratorTask: async (campaignId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      if (campaignId === null || campaignId === undefined) {
        throw new RequiredError('campaignId', 'Required parameter campaignId was null or undefined when calling campaignControllerGetPromocodeGeneratorTask.');
      }
      const localVarPath = `/api/2.0/market/campaign/{campaign_id}/promocode-generator-tasks`
        .replace(`{${'campaign_id'}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить номер последнего промо-кода кампании
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerGetSequence: async (campaignId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      if (campaignId === null || campaignId === undefined) {
        throw new RequiredError('campaignId', 'Required parameter campaignId was null or undefined when calling campaignControllerGetSequence.');
      }
      const localVarPath = `/api/2.0/market/campaign/{campaign_id}/promocode/sequence/last`
        .replace(`{${'campaign_id'}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Сохранить список сгенерированных промо-кодов
     * @param {CreatePromocodePayloadDto} body
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerSavePromoCodes: async (body: CreatePromocodePayloadDto, campaignId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling campaignControllerSavePromoCodes.');
      }
      // verify required parameter 'campaignId' is not null or undefined
      if (campaignId === null || campaignId === undefined) {
        throw new RequiredError('campaignId', 'Required parameter campaignId was null or undefined when calling campaignControllerSavePromoCodes.');
      }
      const localVarPath = `/api/2.0/market/campaign/{campaign_id}/promocode`
        .replace(`{${'campaign_id'}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменить ссылку для загрузки архива промо-кодов
     * @param {UpdateCampaignDownloadUrlDto} body
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerSetDownloadUrl: async (body: UpdateCampaignDownloadUrlDto, campaignId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling campaignControllerSetDownloadUrl.');
      }
      // verify required parameter 'campaignId' is not null or undefined
      if (campaignId === null || campaignId === undefined) {
        throw new RequiredError('campaignId', 'Required parameter campaignId was null or undefined when calling campaignControllerSetDownloadUrl.');
      }
      const localVarPath = `/api/2.0/market/campaign/{campaign_id}`
        .replace(`{${'campaign_id'}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновление акции
     * @param {UpdateCampaignPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerUpdateCampaign: async (body: UpdateCampaignPayloadDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling campaignControllerUpdateCampaign.');
      }
      const localVarPath = `/api/2.0/market/campaign`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновление статуса акции
     * @param {UpdateCampaignStatusPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerUpdateCampaignStatus: async (body: UpdateCampaignStatusPayloadDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling campaignControllerUpdateCampaignStatus.');
      }
      const localVarPath = `/api/2.0/market/campaign-status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Использование QR-кода
     * @param {PostPromocodeQueryFieldsDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerUsePromocode: async (body: PostPromocodeQueryFieldsDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling campaignControllerUsePromocode.');
      }
      const localVarPath = `/api/2.0/market/campaign/promocode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение списка категорий
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketControllerGetCategories: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Покупка товара
     * @param {PurchasePayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketControllerMakePurchase: async (body: PurchasePayloadDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling marketControllerMakePurchase.');
      }
      const localVarPath = `/api/2.0/market/purchase`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создать предложение
     * @param {CreateOfferPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerCreateOffer: async (body: CreateOfferPayloadDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling offerControllerCreateOffer.');
      }
      const localVarPath = `/api/2.0/market/offer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удалить предложение по id
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerDeleteOffer: async (offerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling offerControllerDeleteOffer.');
      }
      const localVarPath = `/api/2.0/market/offer/{offer_id}`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить предложение по id
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerGetOffer: async (offerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling offerControllerGetOffer.');
      }
      const localVarPath = `/api/2.0/market/offers/{offer_id}`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список предложений
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [category] Категория предложения
     * @param {string} [header] Заголовок предложения
     * @param {number} [id] Уникальный идентификатор предложения
     * @param {string} [patchStatus] Статус обновления предложения
     * @param {string} [status] Статус предложения
     * @param {number} [vendor] Уникальный идентификатор продавца
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerGetOffers: async (skip?: number, take?: number, category?: string, header?: string, id?: number, patchStatus?: string, status?: string, vendor?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/offers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (category !== undefined) {
        localVarQueryParameter['category'] = category;
      }

      if (header !== undefined) {
        localVarQueryParameter['header'] = header;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (patchStatus !== undefined) {
        localVarQueryParameter['patch_status'] = patchStatus;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (vendor !== undefined) {
        localVarQueryParameter['vendor'] = vendor;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновить предложение
     * @param {UpdateOfferPayloadDto} body
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerUpdateOffer: async (body: UpdateOfferPayloadDto, offerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling offerControllerUpdateOffer.');
      }
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling offerControllerUpdateOffer.');
      }
      const localVarPath = `/api/2.0/market/offer/{offer_id}`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменить статус предложения
     * @param {OfferStatusPayloadDto} body
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerUpdateOfferStatus: async (body: OfferStatusPayloadDto, offerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling offerControllerUpdateOfferStatus.');
      }
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling offerControllerUpdateOfferStatus.');
      }
      const localVarPath = `/api/2.0/market/offer/{offer_id}/offer-status`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Загрузить изображение для офера
     * @param {string} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerUploadImage: async (image: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      if (image === null || image === undefined) {
        throw new RequiredError('image', 'Required parameter image was null or undefined when calling offerControllerUploadImage.');
      }
      const localVarPath = `/api/2.0/market/offer/{offer_id}/image`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication bearer required

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Подтвердить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerApprove: async (offerPatchId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerPatchId' is not null or undefined
      if (offerPatchId === null || offerPatchId === undefined) {
        throw new RequiredError('offerPatchId', 'Required parameter offerPatchId was null or undefined when calling offerPatchControllerApprove.');
      }
      const localVarPath = `/api/2.0/market/offer-patch/{offer_patch_id}/status/approved`
        .replace(`{${'offer_patch_id'}}`, encodeURIComponent(String(offerPatchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerFindOne: async (offerPatchId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerPatchId' is not null or undefined
      if (offerPatchId === null || offerPatchId === undefined) {
        throw new RequiredError('offerPatchId', 'Required parameter offerPatchId was null or undefined when calling offerPatchControllerFindOne.');
      }
      const localVarPath = `/api/2.0/market/offer-patch/{offer_patch_id}`
        .replace(`{${'offer_patch_id'}}`, encodeURIComponent(String(offerPatchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отклонить обновление предложения
     * @param {OfferPatchResolutionDto} body
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerReject: async (body: OfferPatchResolutionDto, offerPatchId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling offerPatchControllerReject.');
      }
      // verify required parameter 'offerPatchId' is not null or undefined
      if (offerPatchId === null || offerPatchId === undefined) {
        throw new RequiredError('offerPatchId', 'Required parameter offerPatchId was null or undefined when calling offerPatchControllerReject.');
      }
      const localVarPath = `/api/2.0/market/offer-patch/{offer_patch_id}/status/rejected`
        .replace(`{${'offer_patch_id'}}`, encodeURIComponent(String(offerPatchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить отправить обновление предложения на модерацию
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerSend: async (offerPatchId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerPatchId' is not null or undefined
      if (offerPatchId === null || offerPatchId === undefined) {
        throw new RequiredError('offerPatchId', 'Required parameter offerPatchId was null or undefined when calling offerPatchControllerSend.');
      }
      const localVarPath = `/api/2.0/market/offer-patch/{offer_patch_id}/status/pending`
        .replace(`{${'offer_patch_id'}}`, encodeURIComponent(String(offerPatchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создать возможность заработать
     * @param {OpportunityDataDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerCreateOpportunity: async (body: OpportunityDataDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling opportunityControllerCreateOpportunity.');
      }
      const localVarPath = `/api/2.0/market/opportunity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удалить возможность заработать
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerDeleteOpportunity: async (opportunityId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'opportunityId' is not null or undefined
      if (opportunityId === null || opportunityId === undefined) {
        throw new RequiredError('opportunityId', 'Required parameter opportunityId was null or undefined when calling opportunityControllerDeleteOpportunity.');
      }
      const localVarPath = `/api/2.0/market/opportunity/{opportunity_id}`
        .replace(`{${'opportunity_id'}}`, encodeURIComponent(String(opportunityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список возможностей заработать в статусе active
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetApprovedOpportunities: async (skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/opportunity/approved`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список возможностей заработать
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {string} [order] Порядок сортировки записей
     * @param {Array&lt;ModelObject&gt;} [opportunityId] Идентификатор возможности заработка
     * @param {Array&lt;string&gt;} [status] Статус возможности заработать
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetOpportunities: async (skip?: number, take?: number, order?: string, opportunityId?: Array<ModelObject>, status?: Array<string>, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/opportunity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (opportunityId) {
        localVarQueryParameter['opportunity_id'] = opportunityId;
      }

      if (status) {
        localVarQueryParameter['status'] = status;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список возможностей заработать для сортировки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetOpportunitiesPositions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/opportunity/positions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить возможность заработать (по id)
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetOpportunity: async (opportunityId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'opportunityId' is not null or undefined
      if (opportunityId === null || opportunityId === undefined) {
        throw new RequiredError('opportunityId', 'Required parameter opportunityId was null or undefined when calling opportunityControllerGetOpportunity.');
      }
      const localVarPath = `/api/2.0/market/opportunity/{opportunity_id}`
        .replace(`{${'opportunity_id'}}`, encodeURIComponent(String(opportunityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменить сортировку списка возможностей заработать
     * @param {Array&lt;OpportunityPositionDto&gt;} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUpdateOpportunitiesPositions: async (body: Array<OpportunityPositionDto>, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling opportunityControllerUpdateOpportunitiesPositions.');
      }
      const localVarPath = `/api/2.0/market/opportunity/positions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменить возможность заработать
     * @param {OpportunityDataDto} body
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUpdateOpportunity: async (body: OpportunityDataDto, opportunityId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling opportunityControllerUpdateOpportunity.');
      }
      // verify required parameter 'opportunityId' is not null or undefined
      if (opportunityId === null || opportunityId === undefined) {
        throw new RequiredError('opportunityId', 'Required parameter opportunityId was null or undefined when calling opportunityControllerUpdateOpportunity.');
      }
      const localVarPath = `/api/2.0/market/opportunity/{opportunity_id}`
        .replace(`{${'opportunity_id'}}`, encodeURIComponent(String(opportunityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Загрузка изображения
     * @param {string} image
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUploadImage: async (image: string, opportunityId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      if (image === null || image === undefined) {
        throw new RequiredError('image', 'Required parameter image was null or undefined when calling opportunityControllerUploadImage.');
      }
      // verify required parameter 'opportunityId' is not null or undefined
      if (opportunityId === null || opportunityId === undefined) {
        throw new RequiredError('opportunityId', 'Required parameter opportunityId was null or undefined when calling opportunityControllerUploadImage.');
      }
      const localVarPath = `/api/2.0/market/opportunity/{opportunity_id}/image`
        .replace(`{${'opportunity_id'}}`, encodeURIComponent(String(opportunityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication bearer required

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MarketApi - functional programming interface
 * @export
 */
export const MarketApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Создание акции
     * @param {CreateCampaignPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async campaignControllerCreateCampaign(body: CreateCampaignPayloadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCampaignResponseDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .campaignControllerCreateCampaign(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Создание задачи на генерацию QR-кодов
     * @param {CreatePromocodeGeneratorTaskPayloadDto} body
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async campaignControllerCreatePromocodeGeneratorTask(body: CreatePromocodeGeneratorTaskPayloadDto, campaignId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromocodeGeneratorTaskDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .campaignControllerCreatePromocodeGeneratorTask(body, campaignId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Удаление акции
     * @param {DeleteCampaignPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async campaignControllerDeleteCampaign(body: DeleteCampaignPayloadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .campaignControllerDeleteCampaign(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список акций
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [status] Статус акции
     * @param {number} [id] ID акции
     * @param {number} [vendor] ID продавца
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async campaignControllerGetCampaignList(skip?: number, take?: number, start?: string, end?: string, order?: string, status?: string, id?: number, vendor?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCampaignResponseDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .campaignControllerGetCampaignList(skip, take, start, end, order, status, id, vendor, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список промокодов в кампании
     * @param {number} campaignId ID кампании
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async campaignControllerGetPromoCodes(campaignId: number, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPromocodesDto>>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .campaignControllerGetPromoCodes(campaignId, skip, take, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Запрос состояния задач генерации QR-кодов
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async campaignControllerGetPromocodeGeneratorTask(campaignId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PromocodeGeneratorTaskDto>>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .campaignControllerGetPromocodeGeneratorTask(campaignId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить номер последнего промо-кода кампании
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async campaignControllerGetSequence(campaignId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSequenceResponseDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .campaignControllerGetSequence(campaignId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Сохранить список сгенерированных промо-кодов
     * @param {CreatePromocodePayloadDto} body
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async campaignControllerSavePromoCodes(body: CreatePromocodePayloadDto, campaignId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .campaignControllerSavePromoCodes(body, campaignId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Изменить ссылку для загрузки архива промо-кодов
     * @param {UpdateCampaignDownloadUrlDto} body
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async campaignControllerSetDownloadUrl(body: UpdateCampaignDownloadUrlDto, campaignId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .campaignControllerSetDownloadUrl(body, campaignId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Обновление акции
     * @param {UpdateCampaignPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async campaignControllerUpdateCampaign(body: UpdateCampaignPayloadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .campaignControllerUpdateCampaign(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Обновление статуса акции
     * @param {UpdateCampaignStatusPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async campaignControllerUpdateCampaignStatus(body: UpdateCampaignStatusPayloadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .campaignControllerUpdateCampaignStatus(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Использование QR-кода
     * @param {PostPromocodeQueryFieldsDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async campaignControllerUsePromocode(body: PostPromocodeQueryFieldsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsePromocodeResponseDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .campaignControllerUsePromocode(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получение списка категорий
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketControllerGetCategories(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryDto>>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .marketControllerGetCategories(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Покупка товара
     * @param {PurchasePayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketControllerMakePurchase(body: PurchasePayloadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseResponseDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .marketControllerMakePurchase(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Создать предложение
     * @param {CreateOfferPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerCreateOffer(body: CreateOfferPayloadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDataDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .offerControllerCreateOffer(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Удалить предложение по id
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerDeleteOffer(offerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .offerControllerDeleteOffer(offerId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить предложение по id
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerGetOffer(offerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDataWithPersonalDataDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .offerControllerGetOffer(offerId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список предложений
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [category] Категория предложения
     * @param {string} [header] Заголовок предложения
     * @param {number} [id] Уникальный идентификатор предложения
     * @param {string} [patchStatus] Статус обновления предложения
     * @param {string} [status] Статус предложения
     * @param {number} [vendor] Уникальный идентификатор продавца
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerGetOffers(skip?: number, take?: number, category?: string, header?: string, id?: number, patchStatus?: string, status?: string, vendor?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOffersResponseDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .offerControllerGetOffers(skip, take, category, header, id, patchStatus, status, vendor, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Обновить предложение
     * @param {UpdateOfferPayloadDto} body
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerUpdateOffer(body: UpdateOfferPayloadDto, offerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDataWithPatchDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .offerControllerUpdateOffer(body, offerId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Изменить статус предложения
     * @param {OfferStatusPayloadDto} body
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerUpdateOfferStatus(body: OfferStatusPayloadDto, offerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDataWithPatchDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .offerControllerUpdateOfferStatus(body, offerId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Загрузить изображение для офера
     * @param {string} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerControllerUploadImage(image: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageResponseDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .offerControllerUploadImage(image, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Подтвердить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerPatchControllerApprove(offerPatchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferPatchDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .offerPatchControllerApprove(offerPatchId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerPatchControllerFindOne(offerPatchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferPatchDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .offerPatchControllerFindOne(offerPatchId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Отклонить обновление предложения
     * @param {OfferPatchResolutionDto} body
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerPatchControllerReject(body: OfferPatchResolutionDto, offerPatchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferPatchDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .offerPatchControllerReject(body, offerPatchId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить отправить обновление предложения на модерацию
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerPatchControllerSend(offerPatchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferPatchDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .offerPatchControllerSend(offerPatchId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Создать возможность заработать
     * @param {OpportunityDataDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerCreateOpportunity(body: OpportunityDataDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .opportunityControllerCreateOpportunity(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Удалить возможность заработать
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerDeleteOpportunity(opportunityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .opportunityControllerDeleteOpportunity(opportunityId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список возможностей заработать в статусе active
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerGetApprovedOpportunities(skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunitiesListDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .opportunityControllerGetApprovedOpportunities(skip, take, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список возможностей заработать
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {string} [order] Порядок сортировки записей
     * @param {Array&lt;ModelObject&gt;} [opportunityId] Идентификатор возможности заработка
     * @param {Array&lt;string&gt;} [status] Статус возможности заработать
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerGetOpportunities(skip?: number, take?: number, order?: string, opportunityId?: Array<ModelObject>, status?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunitiesListDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .opportunityControllerGetOpportunities(skip, take, order, opportunityId, status, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список возможностей заработать для сортировки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerGetOpportunitiesPositions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpportunityPositionListItemDto>>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .opportunityControllerGetOpportunitiesPositions(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить возможность заработать (по id)
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerGetOpportunity(opportunityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .opportunityControllerGetOpportunity(opportunityId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Изменить сортировку списка возможностей заработать
     * @param {Array&lt;OpportunityPositionDto&gt;} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerUpdateOpportunitiesPositions(body: Array<OpportunityPositionDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpportunityPositionDto>>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .opportunityControllerUpdateOpportunitiesPositions(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Изменить возможность заработать
     * @param {OpportunityDataDto} body
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerUpdateOpportunity(body: OpportunityDataDto, opportunityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .opportunityControllerUpdateOpportunity(body, opportunityId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Загрузка изображения
     * @param {string} image
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerUploadImage(image: string, opportunityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageResponseDto>> {
      const localVarAxiosArgs = await MarketApiAxiosParamCreator(configuration)
        .opportunityControllerUploadImage(image, opportunityId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MarketApi - factory interface
 * @export
 */
export const MarketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Создание акции
     * @param {CreateCampaignPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerCreateCampaign(body: CreateCampaignPayloadDto, options?: any): AxiosPromise<CreateCampaignResponseDto> {
      return MarketApiFp(configuration)
        .campaignControllerCreateCampaign(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Создание задачи на генерацию QR-кодов
     * @param {CreatePromocodeGeneratorTaskPayloadDto} body
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerCreatePromocodeGeneratorTask(body: CreatePromocodeGeneratorTaskPayloadDto, campaignId: number, options?: any): AxiosPromise<PromocodeGeneratorTaskDto> {
      return MarketApiFp(configuration)
        .campaignControllerCreatePromocodeGeneratorTask(body, campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление акции
     * @param {DeleteCampaignPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerDeleteCampaign(body: DeleteCampaignPayloadDto, options?: any): AxiosPromise<void> {
      return MarketApiFp(configuration)
        .campaignControllerDeleteCampaign(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список акций
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [status] Статус акции
     * @param {number} [id] ID акции
     * @param {number} [vendor] ID продавца
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerGetCampaignList(skip?: number, take?: number, start?: string, end?: string, order?: string, status?: string, id?: number, vendor?: number, options?: any): AxiosPromise<GetCampaignResponseDto> {
      return MarketApiFp(configuration)
        .campaignControllerGetCampaignList(skip, take, start, end, order, status, id, vendor, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список промокодов в кампании
     * @param {number} campaignId ID кампании
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerGetPromoCodes(campaignId: number, skip?: number, take?: number, options?: any): AxiosPromise<Array<GetPromocodesDto>> {
      return MarketApiFp(configuration)
        .campaignControllerGetPromoCodes(campaignId, skip, take, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Запрос состояния задач генерации QR-кодов
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerGetPromocodeGeneratorTask(campaignId: number, options?: any): AxiosPromise<Array<PromocodeGeneratorTaskDto>> {
      return MarketApiFp(configuration)
        .campaignControllerGetPromocodeGeneratorTask(campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить номер последнего промо-кода кампании
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerGetSequence(campaignId: number, options?: any): AxiosPromise<GetSequenceResponseDto> {
      return MarketApiFp(configuration)
        .campaignControllerGetSequence(campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Сохранить список сгенерированных промо-кодов
     * @param {CreatePromocodePayloadDto} body
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerSavePromoCodes(body: CreatePromocodePayloadDto, campaignId: number, options?: any): AxiosPromise<string> {
      return MarketApiFp(configuration)
        .campaignControllerSavePromoCodes(body, campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменить ссылку для загрузки архива промо-кодов
     * @param {UpdateCampaignDownloadUrlDto} body
     * @param {number} campaignId ID кампании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerSetDownloadUrl(body: UpdateCampaignDownloadUrlDto, campaignId: number, options?: any): AxiosPromise<boolean> {
      return MarketApiFp(configuration)
        .campaignControllerSetDownloadUrl(body, campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Обновление акции
     * @param {UpdateCampaignPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerUpdateCampaign(body: UpdateCampaignPayloadDto, options?: any): AxiosPromise<boolean> {
      return MarketApiFp(configuration)
        .campaignControllerUpdateCampaign(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Обновление статуса акции
     * @param {UpdateCampaignStatusPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerUpdateCampaignStatus(body: UpdateCampaignStatusPayloadDto, options?: any): AxiosPromise<boolean> {
      return MarketApiFp(configuration)
        .campaignControllerUpdateCampaignStatus(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Использование QR-кода
     * @param {PostPromocodeQueryFieldsDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    campaignControllerUsePromocode(body: PostPromocodeQueryFieldsDto, options?: any): AxiosPromise<UsePromocodeResponseDto> {
      return MarketApiFp(configuration)
        .campaignControllerUsePromocode(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение списка категорий
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketControllerGetCategories(options?: any): AxiosPromise<Array<CategoryDto>> {
      return MarketApiFp(configuration)
        .marketControllerGetCategories(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Покупка товара
     * @param {PurchasePayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketControllerMakePurchase(body: PurchasePayloadDto, options?: any): AxiosPromise<PurchaseResponseDto> {
      return MarketApiFp(configuration)
        .marketControllerMakePurchase(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Создать предложение
     * @param {CreateOfferPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerCreateOffer(body: CreateOfferPayloadDto, options?: any): AxiosPromise<OfferDataDto> {
      return MarketApiFp(configuration)
        .offerControllerCreateOffer(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удалить предложение по id
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerDeleteOffer(offerId: number, options?: any): AxiosPromise<string> {
      return MarketApiFp(configuration)
        .offerControllerDeleteOffer(offerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить предложение по id
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerGetOffer(offerId: number, options?: any): AxiosPromise<OfferDataWithPersonalDataDto> {
      return MarketApiFp(configuration)
        .offerControllerGetOffer(offerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список предложений
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [category] Категория предложения
     * @param {string} [header] Заголовок предложения
     * @param {number} [id] Уникальный идентификатор предложения
     * @param {string} [patchStatus] Статус обновления предложения
     * @param {string} [status] Статус предложения
     * @param {number} [vendor] Уникальный идентификатор продавца
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerGetOffers(skip?: number, take?: number, category?: string, header?: string, id?: number, patchStatus?: string, status?: string, vendor?: number, options?: any): AxiosPromise<GetOffersResponseDto> {
      return MarketApiFp(configuration)
        .offerControllerGetOffers(skip, take, category, header, id, patchStatus, status, vendor, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Обновить предложение
     * @param {UpdateOfferPayloadDto} body
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerUpdateOffer(body: UpdateOfferPayloadDto, offerId: number, options?: any): AxiosPromise<OfferDataWithPatchDto> {
      return MarketApiFp(configuration)
        .offerControllerUpdateOffer(body, offerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменить статус предложения
     * @param {OfferStatusPayloadDto} body
     * @param {number} offerId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerUpdateOfferStatus(body: OfferStatusPayloadDto, offerId: number, options?: any): AxiosPromise<OfferDataWithPatchDto> {
      return MarketApiFp(configuration)
        .offerControllerUpdateOfferStatus(body, offerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Загрузить изображение для офера
     * @param {string} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerControllerUploadImage(image: string, options?: any): AxiosPromise<UploadImageResponseDto> {
      return MarketApiFp(configuration)
        .offerControllerUploadImage(image, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Подтвердить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerApprove(offerPatchId: number, options?: any): AxiosPromise<OfferPatchDto> {
      return MarketApiFp(configuration)
        .offerPatchControllerApprove(offerPatchId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerFindOne(offerPatchId: number, options?: any): AxiosPromise<OfferPatchDto> {
      return MarketApiFp(configuration)
        .offerPatchControllerFindOne(offerPatchId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Отклонить обновление предложения
     * @param {OfferPatchResolutionDto} body
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerReject(body: OfferPatchResolutionDto, offerPatchId: number, options?: any): AxiosPromise<OfferPatchDto> {
      return MarketApiFp(configuration)
        .offerPatchControllerReject(body, offerPatchId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить отправить обновление предложения на модерацию
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerSend(offerPatchId: number, options?: any): AxiosPromise<OfferPatchDto> {
      return MarketApiFp(configuration)
        .offerPatchControllerSend(offerPatchId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Создать возможность заработать
     * @param {OpportunityDataDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerCreateOpportunity(body: OpportunityDataDto, options?: any): AxiosPromise<OpportunityDto> {
      return MarketApiFp(configuration)
        .opportunityControllerCreateOpportunity(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удалить возможность заработать
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerDeleteOpportunity(opportunityId: number, options?: any): AxiosPromise<OpportunityDto> {
      return MarketApiFp(configuration)
        .opportunityControllerDeleteOpportunity(opportunityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список возможностей заработать в статусе active
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetApprovedOpportunities(skip?: number, take?: number, options?: any): AxiosPromise<OpportunitiesListDto> {
      return MarketApiFp(configuration)
        .opportunityControllerGetApprovedOpportunities(skip, take, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список возможностей заработать
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {string} [order] Порядок сортировки записей
     * @param {Array&lt;ModelObject&gt;} [opportunityId] Идентификатор возможности заработка
     * @param {Array&lt;string&gt;} [status] Статус возможности заработать
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetOpportunities(skip?: number, take?: number, order?: string, opportunityId?: Array<ModelObject>, status?: Array<string>, options?: any): AxiosPromise<OpportunitiesListDto> {
      return MarketApiFp(configuration)
        .opportunityControllerGetOpportunities(skip, take, order, opportunityId, status, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список возможностей заработать для сортировки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetOpportunitiesPositions(options?: any): AxiosPromise<Array<OpportunityPositionListItemDto>> {
      return MarketApiFp(configuration)
        .opportunityControllerGetOpportunitiesPositions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить возможность заработать (по id)
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetOpportunity(opportunityId: number, options?: any): AxiosPromise<OpportunityDto> {
      return MarketApiFp(configuration)
        .opportunityControllerGetOpportunity(opportunityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменить сортировку списка возможностей заработать
     * @param {Array&lt;OpportunityPositionDto&gt;} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUpdateOpportunitiesPositions(body: Array<OpportunityPositionDto>, options?: any): AxiosPromise<Array<OpportunityPositionDto>> {
      return MarketApiFp(configuration)
        .opportunityControllerUpdateOpportunitiesPositions(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменить возможность заработать
     * @param {OpportunityDataDto} body
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUpdateOpportunity(body: OpportunityDataDto, opportunityId: number, options?: any): AxiosPromise<OpportunityDto> {
      return MarketApiFp(configuration)
        .opportunityControllerUpdateOpportunity(body, opportunityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Загрузка изображения
     * @param {string} image
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUploadImage(image: string, opportunityId: number, options?: any): AxiosPromise<UploadImageResponseDto> {
      return MarketApiFp(configuration)
        .opportunityControllerUploadImage(image, opportunityId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MarketApi - object-oriented interface
 * @export
 * @class MarketApi
 * @extends {BaseAPI}
 */
export class MarketApi extends BaseAPI {
  /**
   *
   * @summary Создание акции
   * @param {CreateCampaignPayloadDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public campaignControllerCreateCampaign(body: CreateCampaignPayloadDto, options?: any) {
    return MarketApiFp(this.configuration)
      .campaignControllerCreateCampaign(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создание задачи на генерацию QR-кодов
   * @param {CreatePromocodeGeneratorTaskPayloadDto} body
   * @param {number} campaignId ID кампании
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public campaignControllerCreatePromocodeGeneratorTask(body: CreatePromocodeGeneratorTaskPayloadDto, campaignId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .campaignControllerCreatePromocodeGeneratorTask(body, campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление акции
   * @param {DeleteCampaignPayloadDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public campaignControllerDeleteCampaign(body: DeleteCampaignPayloadDto, options?: any) {
    return MarketApiFp(this.configuration)
      .campaignControllerDeleteCampaign(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список акций
   * @param {number} [skip] Пропустить n записей.
   * @param {number} [take] Количество записей в ответе.
   * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
   * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
   * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
   * @param {string} [status] Статус акции
   * @param {number} [id] ID акции
   * @param {number} [vendor] ID продавца
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public campaignControllerGetCampaignList(skip?: number, take?: number, start?: string, end?: string, order?: string, status?: string, id?: number, vendor?: number, options?: any) {
    return MarketApiFp(this.configuration)
      .campaignControllerGetCampaignList(skip, take, start, end, order, status, id, vendor, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список промокодов в кампании
   * @param {number} campaignId ID кампании
   * @param {number} [skip] Пропустить n записей
   * @param {number} [take] Количество записей в ответе
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public campaignControllerGetPromoCodes(campaignId: number, skip?: number, take?: number, options?: any) {
    return MarketApiFp(this.configuration)
      .campaignControllerGetPromoCodes(campaignId, skip, take, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Запрос состояния задач генерации QR-кодов
   * @param {number} campaignId ID кампании
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public campaignControllerGetPromocodeGeneratorTask(campaignId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .campaignControllerGetPromocodeGeneratorTask(campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить номер последнего промо-кода кампании
   * @param {number} campaignId ID кампании
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public campaignControllerGetSequence(campaignId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .campaignControllerGetSequence(campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Сохранить список сгенерированных промо-кодов
   * @param {CreatePromocodePayloadDto} body
   * @param {number} campaignId ID кампании
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public campaignControllerSavePromoCodes(body: CreatePromocodePayloadDto, campaignId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .campaignControllerSavePromoCodes(body, campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменить ссылку для загрузки архива промо-кодов
   * @param {UpdateCampaignDownloadUrlDto} body
   * @param {number} campaignId ID кампании
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public campaignControllerSetDownloadUrl(body: UpdateCampaignDownloadUrlDto, campaignId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .campaignControllerSetDownloadUrl(body, campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновление акции
   * @param {UpdateCampaignPayloadDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public campaignControllerUpdateCampaign(body: UpdateCampaignPayloadDto, options?: any) {
    return MarketApiFp(this.configuration)
      .campaignControllerUpdateCampaign(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновление статуса акции
   * @param {UpdateCampaignStatusPayloadDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public campaignControllerUpdateCampaignStatus(body: UpdateCampaignStatusPayloadDto, options?: any) {
    return MarketApiFp(this.configuration)
      .campaignControllerUpdateCampaignStatus(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Использование QR-кода
   * @param {PostPromocodeQueryFieldsDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public campaignControllerUsePromocode(body: PostPromocodeQueryFieldsDto, options?: any) {
    return MarketApiFp(this.configuration)
      .campaignControllerUsePromocode(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение списка категорий
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public marketControllerGetCategories(options?: any) {
    return MarketApiFp(this.configuration)
      .marketControllerGetCategories(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Покупка товара
   * @param {PurchasePayloadDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public marketControllerMakePurchase(body: PurchasePayloadDto, options?: any) {
    return MarketApiFp(this.configuration)
      .marketControllerMakePurchase(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создать предложение
   * @param {CreateOfferPayloadDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public offerControllerCreateOffer(body: CreateOfferPayloadDto, options?: any) {
    return MarketApiFp(this.configuration)
      .offerControllerCreateOffer(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удалить предложение по id
   * @param {number} offerId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public offerControllerDeleteOffer(offerId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .offerControllerDeleteOffer(offerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить предложение по id
   * @param {number} offerId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public offerControllerGetOffer(offerId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .offerControllerGetOffer(offerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список предложений
   * @param {number} [skip] Пропустить n записей.
   * @param {number} [take] Количество записей в ответе.
   * @param {string} [category] Категория предложения
   * @param {string} [header] Заголовок предложения
   * @param {number} [id] Уникальный идентификатор предложения
   * @param {string} [patchStatus] Статус обновления предложения
   * @param {string} [status] Статус предложения
   * @param {number} [vendor] Уникальный идентификатор продавца
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public offerControllerGetOffers(skip?: number, take?: number, category?: string, header?: string, id?: number, patchStatus?: string, status?: string, vendor?: number, options?: any) {
    return MarketApiFp(this.configuration)
      .offerControllerGetOffers(skip, take, category, header, id, patchStatus, status, vendor, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновить предложение
   * @param {UpdateOfferPayloadDto} body
   * @param {number} offerId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public offerControllerUpdateOffer(body: UpdateOfferPayloadDto, offerId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .offerControllerUpdateOffer(body, offerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменить статус предложения
   * @param {OfferStatusPayloadDto} body
   * @param {number} offerId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public offerControllerUpdateOfferStatus(body: OfferStatusPayloadDto, offerId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .offerControllerUpdateOfferStatus(body, offerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Загрузить изображение для офера
   * @param {string} image
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public offerControllerUploadImage(image: string, options?: any) {
    return MarketApiFp(this.configuration)
      .offerControllerUploadImage(image, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Подтвердить обновление предложения
   * @param {number} offerPatchId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public offerPatchControllerApprove(offerPatchId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .offerPatchControllerApprove(offerPatchId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить обновление предложения
   * @param {number} offerPatchId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public offerPatchControllerFindOne(offerPatchId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .offerPatchControllerFindOne(offerPatchId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отклонить обновление предложения
   * @param {OfferPatchResolutionDto} body
   * @param {number} offerPatchId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public offerPatchControllerReject(body: OfferPatchResolutionDto, offerPatchId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .offerPatchControllerReject(body, offerPatchId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить отправить обновление предложения на модерацию
   * @param {number} offerPatchId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public offerPatchControllerSend(offerPatchId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .offerPatchControllerSend(offerPatchId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создать возможность заработать
   * @param {OpportunityDataDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public opportunityControllerCreateOpportunity(body: OpportunityDataDto, options?: any) {
    return MarketApiFp(this.configuration)
      .opportunityControllerCreateOpportunity(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удалить возможность заработать
   * @param {number} opportunityId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public opportunityControllerDeleteOpportunity(opportunityId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .opportunityControllerDeleteOpportunity(opportunityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список возможностей заработать в статусе active
   * @param {number} [skip] Пропустить n записей
   * @param {number} [take] Количество записей в ответе
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public opportunityControllerGetApprovedOpportunities(skip?: number, take?: number, options?: any) {
    return MarketApiFp(this.configuration)
      .opportunityControllerGetApprovedOpportunities(skip, take, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список возможностей заработать
   * @param {number} [skip] Пропустить n записей
   * @param {number} [take] Количество записей в ответе
   * @param {string} [order] Порядок сортировки записей
   * @param {Array&lt;ModelObject&gt;} [opportunityId] Идентификатор возможности заработка
   * @param {Array&lt;string&gt;} [status] Статус возможности заработать
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public opportunityControllerGetOpportunities(skip?: number, take?: number, order?: string, opportunityId?: Array<ModelObject>, status?: Array<string>, options?: any) {
    return MarketApiFp(this.configuration)
      .opportunityControllerGetOpportunities(skip, take, order, opportunityId, status, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список возможностей заработать для сортировки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public opportunityControllerGetOpportunitiesPositions(options?: any) {
    return MarketApiFp(this.configuration)
      .opportunityControllerGetOpportunitiesPositions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить возможность заработать (по id)
   * @param {number} opportunityId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public opportunityControllerGetOpportunity(opportunityId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .opportunityControllerGetOpportunity(opportunityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменить сортировку списка возможностей заработать
   * @param {Array&lt;OpportunityPositionDto&gt;} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public opportunityControllerUpdateOpportunitiesPositions(body: Array<OpportunityPositionDto>, options?: any) {
    return MarketApiFp(this.configuration)
      .opportunityControllerUpdateOpportunitiesPositions(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменить возможность заработать
   * @param {OpportunityDataDto} body
   * @param {number} opportunityId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public opportunityControllerUpdateOpportunity(body: OpportunityDataDto, opportunityId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .opportunityControllerUpdateOpportunity(body, opportunityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Загрузка изображения
   * @param {string} image
   * @param {number} opportunityId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketApi
   */
  public opportunityControllerUploadImage(image: string, opportunityId: number, options?: any) {
    return MarketApiFp(this.configuration)
      .opportunityControllerUploadImage(image, opportunityId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
